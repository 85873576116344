.mini-banner-container {
  margin-top: 0;
  width: 100%;
  height: 100px;
  position: relative;
  background-size: cover;
  background-position: center; 
  background-repeat: no-repeat;
  background-color: #ececec;
}

.mini-banner-polygon {
  z-index: -1;
  transform: skew(-25deg);
  margin-left: -9%;
  background: rgb(107, 219, 32);
  width: 40.3%;
  height: 120px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 0 0 12px 0;
}

.mini-banner-sm-title {
  z-index: 1;
  color: rgb(255, 255, 255);
  text-transform: uppercase;
  margin: 5vh 5%;
  font-size: 2.5vw;
  padding: 2vw;
  transform: skew(25deg);
  position: relative;
}

@media (max-width: 930px) {
  .mini-banner-container {
    height: 100px;
  }

  .mini-banner-polygon {
    height: 100px;
    z-index: 1;
    transform: skew(-25deg);
    margin-left: -9%;
  }

  .mini-banner-sm-title {
    margin-top: 4vh;
    padding-left: 10%;
    margin-left: 6%;
  }
}

@media (max-width: 555px) {
  .mini-banner-container {
    height: 50px;
  }

  .mini-banner-polygon {
    display: none;
  }

  .mini-banner-sm-title {
    margin-top: 4vh;
    font-size: 20px;
    padding-left: 15%;
    margin-left: 6%;
  }
}
