.home-container {
  width: 100%;
  position: relative;
  max-width: 100%;
  height: 100%;
}

.hero {
  position: relative;
  width: 100%;
  height: 400px;
  overflow: hidden;
}

.hero-img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  opacity: 0.5;
}

.hero-overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background: rgba(0, 0, 0, 0.545);

}

.hero-text {
  font-size: 48px;
  color: white;
  font-weight: bold;
  text-align: center;
  z-index: 2;
}

.hero-call {
  color: white;
}