.button-container {
  width: 100%;
  height: 200px;
  margin-top: 3%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.remote-button {
  margin: 10px;
  width: 20vw;
  height: 10vh;
  border-radius: 8px;
  border: solid 0px;
  cursor: pointer;
  background: #b0f280;
  background: linear-gradient(to bottom, rgba(176,242,128,1) 0%, rgba(186,230,154,1) 40%, rgba(107,219,32,1) 51%, rgba(115,194,58,1) 71%, rgba(107,219,32,1) 100%);
  color: white;
  transition: background-color 0.3s ease, color 0.3s ease;
  font-size: 18px;
  transition: ease-in-out .2s;
  box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 1);}

.remote-button:hover {
  width: 20.1vw;
  height: 10.1vh;
  background: #b0f280;
  background: linear-gradient(to bottom, rgba(176,242,128,1) 0%, rgba(186,230,154,1) 40%, rgba(107,219,32,1) 51%, rgba(115,194,58,1) 71%, rgba(107,219,32,1) 100%);
  color: white;
  font-size: 20px;
  box-shadow: 0px 1px 6px 0px rgba(0, 0, 0, 0.753);
}
