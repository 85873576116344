.banner-container {
  margin-top: 0;
  width: 100%;
  height: 400px;
  position: relative;
  background-size: cover;
  background-position: center; 
  background-repeat: no-repeat;
}

.banner-image {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-size: cover;
  background-position: center; 
  opacity: .9;
  z-index: 0;
}

.banner-polygon {
  z-index: 1;
  transform: skew(-25deg);
  margin-left: -9%;
  background: rgb(110, 252, 15);
  background: linear-gradient(to left, rgba(118, 239, 38, 0.837), rgb(124, 239, 48),  rgb(124, 239, 48));
  width: 40.3%;
  height: 450px;
  display: flex;
  justify-content: center;
  border-radius: 0 0 12px 0;
}

.banner-sm-title {
  z-index: 1;
  color: rgb(255, 255, 255);
  text-transform: uppercase;
  margin: 7vh 7%;
  font-size: 40px;
  padding: 20px;
  transform: skew(25deg);
  position: relative;
  text-shadow: -1px -1px 0 #00000005, 1px -1px 0 #00000005, -1px 1px 0 #00000005, 1px 1px 0 #00000005;
}

@media (max-width: 930px) {
  .banner-container {
    height: 300px;
  }

  .banner-sm-title {
    margin-top: 4vh;
    font-size: 30px;
    padding-left: 10%;
    margin-left: 6%;
  }
}

@media (max-width: 555px) {
  .banner-container {
    height: 150px;
  }

  .banner-polygon {
    display: none;
  }

  .banner-sm-title {
    margin-top: 4vh;
    font-size: 24px;
    padding-left: 15%;
    margin-left: 6%;
  }
}
