select {
  margin: 3% auto;
  width: 75%;
  padding: 10px 60px;
  font-size: 16px;
  border-radius: 12px;
  border: 2px solid #ccc;
  margin-bottom: 15px;
  background-color: #f9f9f9;
  color: #5a5a5aee;
  transition: border-color 0.3s ease;
  display: block;
}


select:focus {
  outline: none;
  border-color: #b0f280;
}

.info-box {
  background-color: #f1f1f1;
  border-left: 5px solid #b0f280;
  padding: 15px;
  margin-top: 10px;
  border-radius: 5px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  opacity: 0;
  max-height: 0;
  overflow: hidden;
  transition: all 0.4s ease;
}

.info-box p {
  margin: 0;
  line-height: 1.5;
  color: #333;
}

select:valid + .info-box {
  opacity: 1;
  max-height: 300px;
  padding: 15px;
}


select:hover {
  border-color: #ff9147;
}

