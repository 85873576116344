.slant-box-container {
  width: 100vw;
  height: 300px;
  display: flex;
  justify-content: center;
}

.box-inner {
  width: 33.3%;
}

.box-text-cont {
  width: 90%;
}

.box-text-cont > p {
  color: white;
  padding: 4%;
  justify-content: center;
}

.box-1 {
  background-color: #444;
}

.box-2 {
  background-color: #1a1a1a;
}

.box-3 {
  background-color: #6bdb20;
  border-radius: 0 0 12px 0;
}

.inner-box-align {
  margin: 2em;
}

.box-title {
  padding-left: 6%;
  color: rgb(255, 255, 255);
  text-transform: uppercase;
}

.box-desc {
  width: 80%;
  margin-left: 2%;
  font-size: 18px;
}

@media only screen and (min-width: 1200px) {

  .slant-box-container  {
    display: flex;
    justify-content: flex-start;
  }

  .box-1 {
    width: 25vw;
  }

  .box-2 {
    width: 25vw;
    margin-left: -70px;
    transform: skew(-25deg);
  }

  .box-3 {
    width: 25vw;
    transform: skew(-25deg);
  }

  .box-2 > *, .box-3 > * {
    transform: skew(25deg);
  }
}

@media (max-width: 768px) {

}

@media (max-width: 480px) {
  .slant-box-container {

  }
}