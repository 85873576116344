.navbar {
  height: 70px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 20px;
  background-color: #1a1a1a;
  position: relative;
}

.homepage-link {
  height: 80px;
  width: auto;
}

.nav-links {
  list-style: none;
  display: flex;
  gap: 20px;
  margin: 0;
  padding: 0;
}

.nav-links a {
  color: #ffffff;
  text-decoration: none;
  font-size: 1em;
}

.nav-links a:hover {
  text-decoration: underline;
}

.hamburger-menu {
  display: none;
}

.info-bar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 60px;
  background-color: #444;
  color: #fff;
}

.info-text {
  font-size: 1em;
  letter-spacing: .2em;
}

.info-phone {
  display: flex;
  align-items: center;
}

.social-icons {
  display: flex;
  gap: 20px;
}

.social-icon {
  font-size: 1em;
  color: #fff;
  transition: color 0.3s ease;
  margin-right: 20px;
}

.social-icon:hover {
  color: #6bdb20;
}

.phone-number {
  color: #fff;
  text-decoration: none;
  margin: 2px;
}

@media (max-width: 768px) {
  .navbar {
    height: 50px;
    padding: 10px;
  }

  .homepage-link {
    height: 40px;
  }

  .nav-links {
    gap: 15px;
  }

  .nav-links a {
    font-size: 0.9em;
  }
}

@media (max-width: 568px) {
  .navbar {
    z-index: 1;
    flex-direction: row;
    align-items: center;
    padding: 5px;
  }

  .logo-image {
    height: 45px;
    display: block;
  }

  .hamburger-menu {
    display: block;
    font-size: 1.5em;
    background: none;
    border: none;
    cursor: pointer;
    color: #ffffff;
    margin-left: auto;
  }

  .nav-links {
    margin-left: auto;
    padding-right: 20px;
    flex-direction: column;
    gap: 10px;
    position: absolute;
    top: 100%;
    right: 0;
    background-color: #1a1a1a;
    width: calc(100% - 20px);
    padding: 10px 0;
    display: none;
  }

  .nav-links.active {
    display: flex;
  }

  .nav-links a {
    font-size: 1em;
    color: #ffffff;
    padding: 5px;
  }

  .info-bar {
    display: none;
  }
}

@media (max-width: 480px) {

}
