.textbox {
  margin: 1%;
  position: relative;
  padding: 25px;
  font-size: 18px;
  z-index: 1;
  color: #5a5a5aee;
  text-align: justify;
}

.textbox-polygon {
  margin-left: 20%;
  transform: skew(-25deg);
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(107, 219, 32, 0.1);
  z-index: -1;
  border-radius: 12px 0 0 2px;
}

@media (max-width: 930px) {
  .textbox {
    margin: 2%;
    font-size: 16px;
  }
}

@media (max-width: 480px) {
  .textbox {
    margin: 3% 5%;
    font-size: 14px;
  }
}

