.link-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
  gap: .25%;
  margin-top: 2%;
}

.logo-link-wrapper {
  width: 8vw;
  height: 8vw;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 10px;
  min-width: 60px;
  min-height: 60px;
}

.logo-link-wrapper img {
  max-width: 100%;
  max-height: 100%;
  object-fit: contain;
}

.logo-link-wrapper:hover {
  cursor: pointer;
}