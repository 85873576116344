.video-container {
  position: relative;
  width: 100%;
  max-width: 1200px;
  height: auto;
  padding-bottom: 56.25%;
  margin: 80px auto;
  overflow: hidden;
}

.about-video {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border: 0;
}

@media (min-width: 1200px) {
  .video-container {
    height: 960px;
    padding-bottom: 0;
  }
}

.about-overlay {
  margin-top: 30px;
  padding: 3%;
}

.dynamic-text {
  line-height: 2;
}
